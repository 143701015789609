import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "greeting":"Welcome to our website"
        },
      },
      hi: {
        translation: {
          "greeting":"हमारी वेबसाइट पर आपका स्वागत है"
        },
      },
      or: {
        translation: {
          "greeting": "ଆମର ୱେବସାଇଟ୍‌କୁ ସ୍ବାଗତ",
        },
      },
    },
    returnObjects:true,
    lng: "hi", // Default language
    fallbackLng: "en", // Fallback language if the current language is not available
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
