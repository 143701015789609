let avtarUrl = "https://api.dicebear.com/9.x/adventurer/svg?seed=",
  avatars = [
    "Midnight",
    "Cookie",
    "Cuddles",
    "Abby",
    "Patches",
    "Chester",
    "Missy",
    "Loki",
    "Jasper",
    "Lucy",
    "Lily",
    "Maggie",
    "Chloe",
    "Callie",
    "Ginger",
    "Molly",
    "Mia",
    "Sassy",
    "Jasmine",
    "Nala",
    "Daisy",
    "Gizmo",
    "Simba",
    "Pepper",
    "Samantha",
    "Bandit",
    "Annie",
    "Whiskers",
    "Jack",
    "Precious",
    "Mittens",
    "Nala",
    "Salem",
    "Sophie",
    "Angel",
    "Oliver",
    "Socks",
    "Zoey",
    "Kiki",
    "George",
  ],
  avatarBackground = [
    "bg-white",
    "bg-sky-400",
    "bg-red-500",
    "bg-yellow-400",
    "bg-emerald-400",
    "bg-pink-500",
    "bg-gray-300",
    "bg-orange-400",
    "bg-sky-200",
    "bg-gray-500",
  ];

const premiumAvatars = [
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799591/uluhfjxiupkhvc0sz3zg.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799592/hlvshapsekfjilxbsggw.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799592/nynq2hcvshdtjuirktwq.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799593/fbtgenflz6iijavs6jx4.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799593/ndaqpocgqclfjdxuzn8t.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799593/lxuktdwwawrepkzwjzbx.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799594/lhicdqw3azffjugzndw8.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799594/e0qm1ibb5w6dpw1it2di.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799595/bbwdkapmzhft9jli7sjv.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799595/iqgtlewxnes2djgxiebu.webp",

  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799628/khh8satedp5y7szhj2lc.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799628/tip550t8is5k5v2rxknn.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799629/lbgef72uc6gwwgy23ayr.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799630/ntwhhp0fesrbz7nmueuc.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799631/zm9nlduljdoz9xfo7mvn.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799631/rdbvqfgj7315zmpfzs7n.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799631/bd690frjvnwfyqqdbfgv.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799631/w894op9ym4oaohzlzl2s.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799632/cactj3xpvh4rzg2teuza.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799632/p4u03tnxr8e6ahyodq7g.webp",

  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799656/sdkplahwonbykrnz0blt.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799656/m0ospayypgsdcfspzdkz.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799656/wa9gqkk1ojhbufz9dhvb.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799659/yaolctwee1hd9bs7yi5m.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799660/xjgzpmvkj9xgddfdf5yd.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799660/gtow2qs8rueogxifiyj6.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799660/gvtqmbavfx2fnl0kvweb.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799661/nddoxdwhwzkdtk8lumyr.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799661/jzedjdxcixfezkx8uagh.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799661/p0geysu1poxy9msfpxnv.webp",

  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799689/t5pcj8rnhannmbsh7igl.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799691/n55yh50eg9s5noxgt8r3.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799692/ayxjgkqg0hosig6eilbe.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799696/pmuhvmmvek7lqt1cdegp.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799696/k1t7m1fuqqb3q6o3griq.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799696/qhaezhx4v7xvv7ueaavb.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799696/srawduiu9svxjx3vack3.webp",
  "https://res.cloudinary.com/db7pikwo4/image/upload/v1725799697/ygsjlqf7x5zg1xgm6f64.webp",
  "https://i.pinimg.com/originals/c0/b9/bd/c0b9bdffecd6fc2d514caf6a57dc71ac.webp",
  "https://i.pinimg.com/originals/86/33/fe/8633fed8ce7e40421403695fd3b6ac28.webp",
  "https://i.pinimg.com/originals/c0/0c/88/c00c880e5e9b41ec38bbb293d82e15f3.webp",
  "https://i.pinimg.com/originals/ac/17/a3/ac17a3b31387df56a09d4f09b91cbe62.webp",
];
export { avtarUrl, avatars, avatarBackground, premiumAvatars };
