import axios from "axios";
import { createContext, useEffect, useState } from "react";
import {
  avatarBackground,
  avatars,
  avtarUrl,
  premiumAvatars,
} from "../assets/Avtars";
export const StoreContext = createContext(null);
const StoreContextProvider = (props) => {
  const [token, setToken] = useState("");
  const [currTab, setCurrTab] = useState("home");
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(false);
  const [matches, setMatches] = useState([]);
  const [match, setMatch] = useState();
  // const serverUrl = "http://localhost:4000/api/v1";
  const serverUrl = `${import.meta.env.VITE_APP_API_URL}/api/v1`
  useEffect(() => {
    if (localStorage.getItem("warpoint")) {
      const data = JSON.parse(localStorage.getItem("warpoint"));
      setToken(data?.token);
      const userfetch = async () => {
        try {
          const user = await axios.get(`${serverUrl}/user/getuserwithtoken`);

          if (user) {
            setUserData(user.data.user);
          } else {
            console.log("Please Login");
          }
        } catch (error) {
          console.log("Error while fetching token data", error);
        }
      };
      userfetch();
    }
  }, []);

  const matchData = [];
  const contextValue = {
    matchData,
    token,
    setToken,
    currTab,
    setCurrTab,
    serverUrl,
    userData,
    setUserData,
    loader,
    setLoader,
    matches,
    setMatches,
    avatarBackground,
    premiumAvatars,
    avatars,
    avtarUrl,
    match,
    setMatch,
  };
  return (
    <StoreContext.Provider value={contextValue}>
      {props.children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
