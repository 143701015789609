import React from "react";

const Loader = () => {
  return (
    <div className="h-screen w-screen fixed bg-black/80 flex items-center justify-center top-0 z-[99999]">
      <div className="flex-col gap-4 w-full flex items-center justify-center">
        <div className="w-16 h-16 border-[5px] text-blue-400 text-4xl animate-spin border-[#d40007] flex items-center justify-center  border-t-[#d40007]/20 rounded-full"></div>
      </div>
      <div className="absolute text-[#d40007] text-[40px] font-semibold capitalize">
        w
      </div>
    </div>
  );
};

export default Loader;
